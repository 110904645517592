"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import { signIn, signOut, useSession } from "next-auth/react";
import Image from "next/image";
import { TiThMenu } from "react-icons/ti";

import ContactInfo from "./ContactInfo";
import Social from "./Social";
import ProSidebarContent from "./ProSidebarContent";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const MobileMenu = () => {
  const router = useRouter();

  const [ref, setRef] = useState<string>();
  const [query, setQuery] = useState<string>();

  const { data: session } = useSession();

  function gotoProperty() {
    if (!ref?.startsWith("MB")) {
      router.push(`/imovel/MB${ref}`);
    } else {
      router.push(`/imovel/${ref}`);
    }
  }

  function search() {
    router.push(`/fast?keyword=${query}&type=sale,rent`);
  }

  return (
    <div
      className="mobilie_header_nav stylehome1 pt-3 h-[5rem]"
      style={{
        background: "transparent",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      }}
    >
      <div className="mobile-menu">
        <div className="header innerpage-style bg-transparent">
          <div className="menu_and_widgets">
            <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
              <TiThMenu
                data-bs-toggle="offcanvas"
                data-bs-target="#mobileMenu"
                aria-controls="mobileMenu"
                size={32}
                color="#fff"
              >
                Menu
              </TiThMenu>
              <a className="menubar" href="/">
                <Image
                  width={125}
                  height={50.5}
                  src="/images/mbras-hb.png"
                  alt="mobile icon"
                />
              </a>
              {/* <Link href="/login"> */}
              <span
                className="icon fz18 far fa-user-circle text-white"
                onClick={() => {
                  session ? signOut() : router.push("/login");
                }}
              />
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* /.mobile-menu meta */}

      <div
        className="offcanvas offcanvas-start mobile_menu-canvas"
        //@ts-ignore
        tabIndex="-1"
        id="mobileMenu"
        aria-labelledby="mobileMenuLabel"
        data-bs-scroll="true"
      >
        <div className="rightside-hidden-bar">
          <div className="hsidebar-header">
            <div
              className="sidebar-close-icon"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <span className="far fa-times"></span>
            </div>
            <h4 className="title">Bem-vindo à MBRAS</h4>
          </div>
          {/* End header */}

          <span className="w-fit px-6 mt-4 block relative">
            <IoSearch className="absolute left-8 top-1/2 -translate-y-1/2" />
            <input
              className="form-control rounded-none bg-transparent border-t-0 border-l-0 border-r-0 border-b-black py-4 p-3 !pl-8 !pr-8 h-6 w-40 focus:rounded-xl"
              placeholder="Ref do imóvel"
              value={ref}
              onChange={(e) => setRef(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  gotoProperty();
                }
              }}
            />
            <button
              className="ud-btn btn-apple w-6 h-6 rounded-full right-8 top-1/2 -translate-y-1/2 p-0 flex items-center justify-center absolute hover:translate-x-1"
              style={{
                background: "#21293A",
              }}
              onClick={(e) => {
                gotoProperty();
              }}
            >
              <IoIosArrowForward className="w-full" />
            </button>
          </span>

          {/* <span className="block w-full px-4 mt-5 relative">
            <input
              placeholder="Local, características, palavra-chave..."
              className="form-control pr-16"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search();
                }
              }}
            />
            <button
              className="ud-btn btn-apple w-10 h-10 right-8 top-1/2 -translate-y-1/2 p-0 flex items-center justify-center absolute hover:translate-x-1"
              style={{
                background: "#21293A",
              }}
              onClick={(e) => {
                search();
              }}
            >
              <IoIosArrowForward className="w-full" />
            </button>
          </span> */}

          <div className="hsidebar-content ">
            <div className="hiddenbar_navbar_content">
              <ProSidebarContent />
              {/* End .hiddenbar_navbar_menu */}

              <div className="hiddenbar_footer position-relative bdrt1">
                <div className="row pt45 pb30 pl30">
                  <ContactInfo />
                </div>
                {/* End .row */}

                <div className="row pt30 pb30 bdrt1">
                  <div className="col-auto">
                    <div className="social-style-sidebar d-flex align-items-center pl30">
                      <p className="me-4 mb-0">Siga-nos</p>
                      <Social />
                    </div>
                  </div>
                </div>
              </div>
              {/* hiddenbar_footer */}
            </div>
          </div>
          {/* End hsidebar-content */}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
